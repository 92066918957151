// import AboutUsPage from "../components/Aboutpage"
import About from "../pages/about/About"

export default function AboutLayout() {
  return (
    <>
      <About />
    </>
  )
}
