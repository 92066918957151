import { Container } from '@mui/material'
import React from 'react'

const HasContract = () => {
  return (
    <Container sx={{my:"50px"}}>
    <iframe src="https://drive.google.com/file/d/1InyEZgld1U0HpNbXDMmU4P7JjoJZ4CIJ/preview" title="myFrame" height="1200" width="1000" ></iframe>
    </Container>
  )
}

export default HasContract